<template>
	<manageLayout>
		<div class="p-inside-manage">
			<h1>Accounts</h1>
			<button type="button" class="create_btn secondary_btn small" v-ripple @click="showModalCreateNewAccount">New Account</button>
			<div class="wrap_manage_table">
				<div class="top_bar">
					<div class="tabs_section">
						<ul>
							<li :class="{'active': topFilter === 'all'}" @click="changeTopFilter('all')">All</li>
							<li :class="{'active': topFilter === 'artist'}" @click="changeTopFilter('artist')">Artist</li>
							<li :class="{'active': topFilter === 'label'}" @click="changeTopFilter('label')">Label</li>
						</ul>
					</div>
					<div class="search_section" :class="{active: activeSearch}">
						<span class="icon"></span>
						<input type="text" placeholder="Search accounts" v-model="searchManageAccounts" @focus="activeSearch = true" @blur="activeSearch = false">
					</div>
				</div>

				<ManageAccountsTable :data="userAccounts" :type-accounts="topFilter"></ManageAccountsTable>
			</div>
		</div>

		<newAccount v-if="modalCreateNewAccount" v-model="modalCreateNewAccount" :uses="'accounts'" :done-modal="true"></newAccount>

		<comingSoonDialog v-if="modalComingSoonDialog" v-model="modalComingSoonDialog"></comingSoonDialog>
	</manageLayout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import manageLayout from "@/layouts/manageLayout";
import ManageAccountsTable from "@/components/manage/accounts/table.vue";
import newAccount from "@/components/newAccount.vue";
import comingSoonDialog from '@/components/dialogs/comingSoonDialog.vue';

export default {
	name: "accounts",
	components: {
		manageLayout,
        ManageAccountsTable,
		newAccount,
		comingSoonDialog
	},
	data() {
		return {
			searchManageAccounts: '',
			activeSearch: false,
			modalCreateNewAccount: false,
			modalComingSoonDialog: false,
            userAccountLoading: true,
		}
	},
	watch: {
		topFilter() {
            this.setFirstPage();
			this.getAccounts();
		},
		searchManageAccounts() {
			this.setFirstPage();
			this.getAccounts();
		},
		orderBy() {
			this.setFirstPage();
			this.getAccounts();
		},
		currentPage() {
			this.getAccounts();
		},
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			isAdmin: 'isAdmin',
			topFilter: 'manageAccounts/TOP_FILTER',
			textSearchAccounts: 'manageAccounts/SEARCH_ACCOUNTS',
			orderBy: 'manageAccounts/ORDER_BY',
			currentPage: 'manageAccounts/CURRENT_PAGE',

            userAccounts: 'USER_ACCOUNTS'
		})
	},
	created() {
		if (this.$route.query.redirect_status === 'succeeded') {
			this.modalCreateNewAccount = true;
			// this.$router.replace({ query: {} });
		}
		if (this.textSearchAccounts.length) {
			this.searchManageAccounts = this.textSearchAccounts;
		}
	},
	mounted() {
		this.searchManageAccounts = '';
		this.getAccounts();
	},

	methods: {
		...mapActions({
			createArtistSubscription: 'CREATE_ARTIST_SUBSCRIPTION',
            getUserAccounts: 'GET_USER_ACCOUNTS'
		}),
        getAccounts() {
			this.scrollToTop();

			if (this.userID) {
                let params = {
                    owner: this.userID,
                    page: this.currentPage,
                    deletedAt: false,
                };

                if (this.topFilter !== 'all') {
                    params["type"] = this.topFilter;
                }

                let searchValue = this.searchManageAccounts.length ? this.searchManageAccounts : '';
                let search = searchValue.length ? {name: this.searchManageAccounts} : undefined;
                this.$store.commit('manageAccounts/SET_SEARCH_ACCOUNTS', searchValue);

                this.getUserAccounts(Object.assign(params, search, this.orderBy))
			} else {
				console.log('getAccounts, need userID');
			}
		},
		changeTopFilter(filter) {
			this.$store.commit('manageAccounts/SET_TOP_FILTER', filter);
			this.searchManageAccounts = '';
		},
		showModalCreateNewAccount() {
			if (this.isAdmin) {
				this.modalCreateNewAccount = true;
			} else {
				this.modalComingSoonDialog = true;
			}
		},

		setFirstPage() {
			this.$store.commit('manageAccounts/SET_CURRENT_PAGE', 1);
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		}
	}
}
</script>

<style scoped>

</style>
